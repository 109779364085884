// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-gallery-additions-tsx": () => import("./../../../src/pages/gallery/additions.tsx" /* webpackChunkName: "component---src-pages-gallery-additions-tsx" */),
  "component---src-pages-gallery-custom-homes-tsx": () => import("./../../../src/pages/gallery/custom-homes.tsx" /* webpackChunkName: "component---src-pages-gallery-custom-homes-tsx" */),
  "component---src-pages-gallery-garages-tsx": () => import("./../../../src/pages/gallery/garages.tsx" /* webpackChunkName: "component---src-pages-gallery-garages-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-gallery-remodels-tsx": () => import("./../../../src/pages/gallery/remodels.tsx" /* webpackChunkName: "component---src-pages-gallery-remodels-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-resources-building-101-tsx": () => import("./../../../src/pages/resources/building-101.tsx" /* webpackChunkName: "component---src-pages-resources-building-101-tsx" */),
  "component---src-pages-resources-faq-tsx": () => import("./../../../src/pages/resources/faq.tsx" /* webpackChunkName: "component---src-pages-resources-faq-tsx" */),
  "component---src-pages-resources-our-process-tsx": () => import("./../../../src/pages/resources/our-process.tsx" /* webpackChunkName: "component---src-pages-resources-our-process-tsx" */),
  "component---src-pages-resources-what-to-expect-tsx": () => import("./../../../src/pages/resources/what-to-expect.tsx" /* webpackChunkName: "component---src-pages-resources-what-to-expect-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-pages-vendors-tsx": () => import("./../../../src/pages/vendors.tsx" /* webpackChunkName: "component---src-pages-vendors-tsx" */)
}

